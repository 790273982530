<template>
  <v-card :min-height="height">
    <v-card-title class="blue-grey darken-1 white--text justify-center">
      <span class="title">History of Quickinspect.me</span>
    </v-card-title>
    <v-card-text>
      <v-timeline>
        <v-timeline-item
          v-for="(item, i) in items"
          :key="i"
          :color="item.color"
          small
        >
          <template v-slot:opposite>
            <span
              :class="`headline font-weight-bold ${item.color}--text`"
              v-text="item.item"
            ></span>
          </template>
          <v-card>
            <v-card-title :class="`${item.color}--text`">{{
              item.title
            }}</v-card-title>
            <v-card-text>
              <div>{{ item.text }}</div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-card-title class="blue-grey darken-1 white--text justify-center mt-5">
      <span class="title">The team of QuickInspect.me</span>
    </v-card-title>
    <v-row no-gutters>
      <v-col md="6" offset-md="3">
        <v-card class="ma-2" tile flat>
          <v-card-text class="primary--text">
            <p class="text-justify subtitle-1">
              We are a small team of developers that is doing this project to
              have fun and learn new technologies. We want to help you with the
              digitalization and engage further in making renewable energy
              sources cheaper. We don't want you to pay more than neccessary so
              we are constantly trying to keep the costs low. No unneccessary
              advertisement and small hierarchies are helping with that.
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" xs="12" md="6">
        <v-card class="ma-2" tile> </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  components: {},
  data: () => ({
    height: window.innerHeight - 200,
    alignments: ["start", "center", "end"],
    items: [
      {
        color: "primary",
        item: "December 2018",
        title: "Idea",
        text:
          "The first idea of a simple App came to our mind. The Offshore Wind industry was obviuosly lacking in such. ",
      },
      {
        color: "primary",
        item: "January 2019",
        title: "Development start",
        text:
          "Specifications and expectations were set. The used techology was choosen and the development began.",
      },
      {
        color: "primary",
        item: "August 2019",
        title: "Beta testing",
        text:
          "The app was tested by users that were not part of the development team.",
      },
      {
        color: "primary",
        item: "December 2019",
        title: "App finalization",
        text:
          "Testing feedback was processed and the finalization of the App started.",
      },
      {
        color: "primary",
        item: "January 2020",
        title: "Formation of Quickinspect.me as a company",
        text:
          "Quickinspect.me was incorporated and is ready help you digitalize at very low costs :)",
      },
    ],
  }),
};
</script>